/**
 * Jumbotron
 */
.jumbotron {
  border-radius: 0;
}

/**
 * Pulsating Loading Animation
 */
.content-loading {
  opacity: 1;
  animation: fadeInOut 1s linear;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0.2 }
  50% { opacity: 0.7 }
}


//________________________________________________________________________________________________________________________ STYLES
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

.bodyClass {
  background: url('../img/suspigsys_bg.jpeg') no-repeat center fixed;
  background-size: cover;
  font-size: 15px;
  font-family: 'Roboto' !important;
}

a{
  text-decoration: none !important;
}

h1{
  color: white;
}

